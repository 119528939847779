<template>
	<div>
    <b-card no-body>
      <b-card title="Lens Package Price" header-tag="header">
			<form class="form" novalidate="novalidate" id="product_basic">
				<b-card-text>
					<b-row>
						<b-col lg="3" md="6">
							<b-form-group>
								<label>Glass Ussage</label>
								<b-form-select v-model="selectedUsage" :options="glassUsages"></b-form-select>
							</b-form-group>
						</b-col>
						<b-col lg="3" md="6">
							<b-form-group>
								<label>Group Label</label>
								<b-form-input v-model="form.name" placeholder="$77 to $99"></b-form-input>
							</b-form-group>
						</b-col>
						
				
					</b-row>
				<b-tabs content-class="mt-3" fill>

					<b-tab v-for="(pack,i) in packages" :key="pack.id" :title="pack.name" :active="i==0?true:false">
						<b-form-input
								v-model="form.packageInfo[pack.id].packageId"
								hidden
						></b-form-input>
					<div v-for="lens in lensTypes" :key="lens.id">
						<b-row class="pt-5" v-if="lens.children == undefined">
						<b-col lg="3" md="6" >
							<b-form-group>
							<label>{{lens.name}}</label>
							<b-form-input
								v-model="form.packageInfo[pack.id].typeInfo[lens.id].price"
								placeholder="0.00"
								type="number"
								min="0"
							></b-form-input>
							</b-form-group>
						</b-col>
						</b-row>
						<div v-else>
						<h3 class="pb-4">{{lens.name}}</h3>
						<b-card class="bg-light mb-5">
						<div  v-for="coating in lens.children" :key="coating.name">
						<h6 class="pb-4">{{coating.name}}</h6>
						<b-row class="pb-5">

							<b-col lg="3" md="4" sm="6" v-for="color in coating.children" :key="color.name">
							<div class="border bg-white">
								<div class="row no-gutters align-items-center">
								<div class="border-right col pl-1">
									<img :src="imgPath+color.preview_value" width="25" />
									<span class="font-weight-bolder"> {{color.name}}</span>
								</div>
								<div class="border-right col">
									<b-form-input
									v-model="form.packageInfo[pack.id].typeInfo[color.id].price"
									type="number"
									min="0"
									placeholder="0.00"
									class="border-0"
									size="sm"
									></b-form-input>
								</div>
								</div>
							</div>
							</b-col>
						
						</b-row>
						</div>
						</b-card>
						</div>
					</div>
					</b-tab>
				</b-tabs>
					<b-row>
						<b-col class="text-right">
							<button ref="kt_login_signin_submit" class="btn btn-sm btn-outline-primary font-size-h6">
									<i class="las la-save"></i> Save
							</button>
						</b-col>
					</b-row>
				</b-card-text>
			</form>
      </b-card>
    </b-card>
  </div>
</template>

<script>
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
	import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

	// FormValidation plugins
	import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
	import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
	import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
	import KTUtil from "@/assets/js/components/util";
	import Swal from "sweetalert2";
	//api services
	import LensPackageService from "@/core/services/api/lensPackage";
	export default {
		components: {},
		data() {
			return {
				id: this.$route.params.id?parseInt(this.$route.params.id):null,
				packages: [],
				lensTypes: [],
				glassUsages: [],
				selectedUsage: null,
				formDetails: {},
				imgPath:process.env.VUE_APP_API_STORAGE_URL
			};
		},
		mounted() {
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: "Dashboard",
					route: "/dashboard",
				},
				{
					title: "Lens Price Group",
					route: "/lens-price",
				},
				{
					title: "Lens Price Group : New",
				},
			]);
			this.getGlassUsages();
			if(this.id != null && this.selectedUsage !=null){
				this.getLensPriceGroupId();
			}
      		this.loadPackages();
      		this.loadLensTypes();
			
			const signin_form = KTUtil.getById("product_basic");
			this.fv = formValidation(signin_form, {
				fields: {
					name: {
						validators: {
							notEmpty: {
								message: "Name is required",
							},
						},
					},
					group: {
						validators: {
							notEmpty: {
								message: "Attribute group is required",
							},
						},
					},
				},
				plugins: {
					trigger: new Trigger(),
					submitButton: new SubmitButton(),
					bootstrap: new Bootstrap(),
				},
			});
			this.fv.on("core.form.valid", () => {
				// set spinner to submit button
				const submitButton = this.$refs["kt_login_signin_submit"];
				submitButton.classList.add("spinner", "spinner-light", "spinner-right");
				var vm = this;
				if(vm.id == null){
					vm.createPackage(function(response){
						vm.response(response,submitButton);
					});
				}else{
					vm.updatePackage(function(response){
						vm.response(response,submitButton);
					});
				}
	
			});
		},
		methods: {
			response(response,submitButton){
				var vm = this;
				if (response) {
					let timerInterval;
					Swal.fire({
						title: response.message,
						timer: 500,
						icon: "success",
						timerProgressBar: true,
						showConfirmButton: false,
						showCancelButton: false,
						didOpen: () => {
							Swal.showLoading();
							const b = Swal.getHtmlContainer().querySelector("b");
							timerInterval = setInterval(() => {
								b.textContent = Swal.getTimerLeft();
							}, 100);
						},
						willClose: () => {
							clearInterval(timerInterval);
						},
					}).then((result) => {
						vm.$router.push({ name: "lens-price" })
						/* Read more about handling dismissals below */
						if (result.dismiss === Swal.DismissReason.timer) {

						}
					});
				}
				submitButton.classList.remove(
					"spinner",
					"spinner-light",
					"spinner-right",
					"disabled"
				);
			},
			async getGlassUsages(){
				var response = await LensPackageService.getGlassUsages();
				
				response.map((e)=>{
					this.glassUsages.push ({
						value:e.id,
						text:e.title
					})
				});
				this.selectedUsage = this.glassUsages[0].value;
			},
			async loadPackages() {
				var response = await LensPackageService.getLensPackages();
					this.packages = response;
			}, 
			async loadLensTypes() {
				var response = await LensPackageService.getLensTypes();
					this.lensTypes = response;
			},
			async getLensPriceGroupId() {
				var response = await LensPackageService.getLensPriceGroupById(this.id,this.selectedUsage);
				this.formDetails = response;
			},
			createPackage(callback) {
				var vm = this;
				LensPackageService.createPackage(vm.form,vm.selectedUsage).then(function (response) {
					 callback(response);
				});
			},
			updatePackage(callback) {
				var vm = this;
				vm.form.usageID = vm.selectedUsage;
				LensPackageService.updatePackage(vm.id,vm.form).then(function (response) {
					 callback(response);
				});
			},  
		},
		computed: {
			form:function() {
				var id = this.id;
				var formDetails = this.formDetails;
				var form = {
					name: id?formDetails.name:"",
					sort_order:1,
					packageInfo:{}
				};
				for(const pack of this.packages) {
					var packInfo = {
						packageId:pack.id,
						typeInfo:{}
					}
					for(const lens of this.lensTypes){
						if(lens.children != undefined){
							for(const subType of lens.children){
								if(subType.children != undefined){
									subType.children.map(element=>{
										var price ="0.00";
										if(id && formDetails.packageGroupPricing){
											var item = formDetails.packageGroupPricing.find(el => {
												return el.package_id == pack.id && el.type_id == element.id
											});
											if(item != undefined)
												price = item.price;
										}
										packInfo.typeInfo[element.id] = {
											typeID:element.id,
											price: price
										}
									})
								}
								packInfo.typeInfo[subType.id] = {
									typeID: subType.id,
									price: "0.00"
								}
							}
							packInfo.typeInfo[lens.id] = {
								typeID: lens.id,
								price: "0.00"
							}
						}else{
							var price ="0.00";
							if(id  && formDetails.packageGroupPricing){
								var item = formDetails.packageGroupPricing.find(el => {
									return el.package_id == pack.id && el.type_id == lens.id
								});
								if(item != undefined){
									price = item.price;
								}
							}
							packInfo.typeInfo[lens.id] = {
								typeID: lens.id,
								price: price
							}
						}
						
					}
					form.packageInfo[pack.id] = packInfo
				}
				return form;
			}
		},
		watch: {
			selectedUsage: function(val) {
				this.getLensPriceGroupId();
			}
		},
	};
</script>

<style></style>